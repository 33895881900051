import * as React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from '@components';
import * as styles from './404.module.scss';

const NotFoundPage = ({ intl: { formatMessage } }) => {
  return (
    <main className={styles.container_404}>
      <section className={styles.container_404__content}>
        <h1 className={styles.container_404__title}>404</h1>
        <h5 className={styles.container_404__subtitle}>
          {formatMessage({ id: 'pageNotFound' })}
        </h5>
        <p className={styles.container_404__text}>
          {formatMessage({ id: 'soSorry' })}
        </p>
        <Button>
          <Link to="/" className={styles.container_404__link}>
            <p className="btn_text">{formatMessage({ id: 'goHome' })}</p>
          </Link>
        </Button>
      </section>
    </main>
  );
};

export default injectIntl(NotFoundPage);

NotFoundPage.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export const Head = () => <title>Not found</title>;
